import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */

const TabPanel = ( { children, slug, hidden = false, className } ) => {

	return (
		<div role="tabpanel"
		     className={`${className} ${hidden ? 'is-active' : ''}`}
		     tabIndex="0"
		     aria-labelledby={`tab-${slug}`}>
			<div className="tab-panel__inner">
				{children}
			</div>

		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	slug: PropTypes.string,
	hidden: PropTypes.bool,
}

export default styled( TabPanel )`
	height: 0;
    pointer-events: none;
    transition: all .3s ease-in-out;
    outline: none;
	.tab-panel__inner {
		padding: 2rem 0;
	    opacity: 0;
        transition: all .3s ease-in-out;
        @media screen and (min-width: 960px) {
        	padding: 3rem 0;
        }
	}
	&.is-active {
		height: auto;
	    pointer-events: auto;
	    .tab-panel__inner {
	        opacity: 1;
	    }
	}
	
	ul {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 0.5rem;
		
		@media screen and (min-width: 769px) {
			grid-template-columns: 1fr 1fr;
			row-gap: 0.25rem;
		}
	}
	
`
