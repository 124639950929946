import PropTypes from 'prop-types'
import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

/**
 * Internal components
 */

const ContactForm = ( { endpoint, className } ) => {

	const [contactData, setContactData] = useState( {
		name: '',
		email: '',
		phone: '',
		message: '',
		honey: ''
	} )
	const [state, setState] = useState( {
		submitting: false,
		sent: false,
		buttonHtml: 'Submit'
	} )

	const handleInputChange = ( event ) => {
		const target = event.target
		setContactData( {
			...contactData,
			[ target.name ]: target.value
		} )
	}

	const handleSubmit = ( event ) => {
		event.preventDefault()
		console.log( 'onSubmit', endpoint, state, contactData )
		if ( !endpoint ) return false
		if ( state.submitting ) return false
		if ( contactData.honey ) return false
		if ( contactData.email.match( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) === null ) {
			setState( { ...state, buttonHtml: 'Invalid Email' } )
			setTimeout( () => {
				setState( { ...state, buttonHtml: 'Send' } )
			}, 2000 )
			return false
		}
		setState( { ...state, submitting: true, buttonHtml: 'Sending' } )

		axios( {
			method: 'POST',
			url: endpoint,
			timeout: 15000,
			data: contactData
		} ).then( res => {
			if ( res.status === 200 ) {
				setState( {
					submitting: true,
					sent: true,
					buttonHtml: 'Sent!'
				} )
			} else {
				setState( {
					submitting: false,
					sent: false,
					buttonHtml: 'Submit'
				} )
			}
		} )

	}

	return (
		<form className={`contact-form ${className}`} onSubmit={handleSubmit}>
			<div className="contact-form__item">
				<label htmlFor="name">Name</label>
				<input id="name" name="name" type="text" value={contactData.name} onChange={handleInputChange}/>
			</div>
			<div className="contact-form__item">
				<label htmlFor="email">Email</label>
				<input id="email" name="email" type="email" value={contactData.email} onChange={handleInputChange}/>
			</div>
			<div className="contact-form__item">
				<label htmlFor="phone">Phone</label>
				<input id="phone" name="phone" type="text" value={contactData.phone} onChange={handleInputChange}/>
			</div>
			<div className="contact-form__item">
				<label htmlFor="message">Message</label>
				<textarea id="message" name="message" rows="3" value={contactData.message}
				          onChange={handleInputChange} />
			</div>
			<div className="contact-form__honey">
				<label htmlFor="honey">Honey</label>
				<input id="honey" name="honey" type="text" value={contactData.honey} onChange={handleInputChange}/>
			</div>
			<div className="contact-form__foot">
				<button className={`button`}><span>{state.buttonHtml}</span></button>
			</div>
		</form>
	)
}

ContactForm.propTypes = {
	endpoint: PropTypes.string
}

export default styled( ContactForm )`
	label {
      display: block;
      color: var(--primary-color);
   }
   .contact-form__item {
        margin-top: 1.5rem;
   }
   .contact-form__honey {
        position: fixed;
        top: -9999px;
        left: -9999px;
   }
  input,
  textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid var( --primary-color-light);
    input {
      padding: 0.5rem 0;
    }
  }
  
  button {
   margin-top: 1.5rem;
  display: inline-block;
    padding: 0.875rem 1rem;
    min-width: 3rem;
    font-family: inherit;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    font-size: 0.875rem;
    color: var(--white);
    letter-spacing: var(--letter-spacing-xxs);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background: var(--primary-color);
    border: 0.125rem solid var(--primary-color);
    border-radius: 0;
    transition: all .2s ease-in-out;
    outline: none;
    &:hover { 
   
        letter-spacing: var(--letter-spacing-l);
        box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    }
  }

`