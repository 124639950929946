import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ParseHTML from '../particles/ParseHTML'

const StatisticCard = ( { title, description, className } ) => {


	const header = () => {
		if ( title ) {
			return (
				<header className="card-header" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="750">
					<h2 className="card-title">{title}</h2>
				</header>
			)
		}
	}
	const footer = () => {
		if ( description ) {
			return (
				<footer className="card-footer" data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="750">
					{
						ParseHTML(description)
					}
				</footer>
			)
		}
	}

	return (
		<article className={`statistic-card ${className}`} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
			{header()}
			{footer()}
		</article>
	)
}

StatisticCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
}

export default styled( StatisticCard )`
	padding: 1.5rem 1rem;
	background: var(--primary-color);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media screen and (min-width: 967px) {
		padding: 2rem 1.5rem;
	}

	.card-title {
		margin: 0;
		color: var(--white);
		font-family: var(--font-family-sans-serif);
		font-size: 2rem;
		font-weight: var(--font-weight-bold);
		line-height: 1;
		@media screen and (min-width: 967px) {
			font-size: 2.75rem; 
		}
	}
	

	.card-footer {
		margin-top: 0.875rem;
			p {
			margin: 0;
			color: var(--white);
			font-size: 1rem; 
		}
	}

`