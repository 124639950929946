import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */

import useRelative from '../particles/hooks/useRelative'


const Button = ( { title, url, target = '_self',  className, primary, light } ) => {

	return (
		<a href={useRelative(url)} className={`button ${className}${light ? ' button-light' : ''}`} target={target}>{title}</a>
	)
}

Button.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	target: PropTypes.string,

	primary: PropTypes.bool,
	light: PropTypes.bool
}

export default styled(Button)`
	display: inline-block;
    padding: 0.875rem 1rem;
    min-width: 3rem;
    font-family: inherit;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    font-size: 0.875rem;
    color: var(--white);
    letter-spacing: var(--letter-spacing-xxs);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background: var(--primary-color);
    border: 0.125rem solid var(--primary-color);
    border-radius: 0;
    transition: all .2s ease-in-out;
    outline: none;
    &:hover { 
        background: none;
        letter-spacing: var(--letter-spacing-l);
        box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    }
    &.button-light {
        background: var(--white);
        color: var(--primary-color);
        border: 0.125rem solid var(--white);
         &:hover { 
            background: none;
            color: var(--white);
         }
    }	
`
