import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */
import Section from './../organisms/Section'
import Container from '../organisms/Container'
import Card from '../atoms/Card'

const ImageCard = ( { content, className } ) => {

	return (
		<Section className={`image-card-content ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				<Card title={content.title} content={content.content} button={content.button} imageObj={content.image}/>
			</Container>
		</Section>
	)
}

ImageCard.propTypes = {
	content: PropTypes.object,
}

export default styled( ImageCard )``