import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */
import Section from './../organisms/Section'
import Container from '../organisms/Container'
import ParseHTML from '../particles/ParseHTML'
import Img from 'gatsby-image/index'
import Button from '../atoms/Button'

const ImageContent = ( { content, className } ) => {

	const header = () => {
		if ( content.title || content.content || content.button ) {
			return (
				<div className="image__content">
					<div className="image-content__content max-width-m">
						<header className={`image-content__header`} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
							{content.title && <h2 className="image-content__title h2">{content.title}</h2>}
						</header>
						{content.content &&
						<div className="the-content" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">{ParseHTML( content.content )}</div>}
						{content.button && <footer className="image-content__footer" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
							<Button title={content.button.title} url={content.button.url}
							        target={content.button.target}/>
						</footer>}
					</div>
				</div>
			)
		}
	}

	const image = () => {
		if ( content.image ) {
			let imageSrc
			if ( content.image.imageFile.childImageSharp !== null ) {
				imageSrc = content.image.imageFile.childImageSharp.fluid
			} else {
				imageSrc = {
					src: content.image.sourceUrl,
					sizes: content.image.sizes,
					srcSet: content.image.srcSet,
				}
			}
			return (
				<div className="image-content__figure">
					<Img className="image-content__image"
					     alt={content.image.altText}
					     sizes={{
						     ...content.image.sizes,
						     ...imageSrc,
						     aspectRatio: 1636 / 634,
						     objectFit: 'cover',
						     objectPosition: '50% 50%'
					     }}
					     objectFit="cover"
					     objectPosition="50% 50%"
					/>
				</div>
			)
		}
	}

	return (
		<Section className={`image-content ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				<div className="image-content__inner">
					{image()}
					{header()}
				</div>
			</Container>
		</Section>
	)
}

ImageContent.propTypes = {
	content: PropTypes.object,
}

export default styled( ImageContent )`
	.image__content {
		@media screen and (min-width: 1023px) {
			position: absolute;
			top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
			display: flex;
			flex: auto;
			flex-direction: column;
			justify-content: flex-end;
			z-index: 9;
			background: rgba(0,0,0, .2);
		}
	}
	
	.image-content__inner {
		@media screen and (min-width: 1023px) {
			position: relative;
			display: flex;
			flex: auto;
			flex-direction: column;
			overflow: hidden;
		}
	}
	.image-content__figure {
		flex: none;
	}
	
	.image-content__content {
		padding: 1.5rem 0;
		@media screen and (min-width: 1023px) {
			margin: 2rem;
			padding: 1.5rem 2rem;
			background: var(--white);
		}
	}
	
	.image-content__header {
		margin-bottom: 1rem;
		@media screen and (min-width: 640px) {
			margin-bottom: 1.5rem;
		}
	}
		
	.image-content__title {
		font-size: 1.5rem;
		@media screen and (min-width: 640px) {
			font-size: 2rem;
		}
	}
	.image-content__footer {
		margin-top: 1rem;
		@media screen and (min-width: 640px) {
			margin-top: 1.5rem;
		}
	}	
`