import { useStaticQuery, graphql } from 'gatsby'

export const useQueryTestimonials = () => {
	const { wordpress } = useStaticQuery(
		graphql`
            query allTestimonials {
                wordpress {
                    testimonials(first: 8, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}) {
                        nodes {
                            id
                            databaseId
                            title
                            content
                        }
                    }
                }
            }
		`
	)

	return wordpress.testimonials.nodes
}