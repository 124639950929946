import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'
import ServiceCard from '../atoms/ServiceCard'

const Services = ( { content, className } ) => {

	const header = () => {
		if ( content.title || content.content ){
			return  (
				<header className="services__header" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
					{content.title && <h3 className="title h1">{content.title}</h3> }
					{content.content && <div className="the-content max-width-m">{ParseHTML(content.content)}</div>}
				</header>
			)
		}
	}


	return (
		<Section className={`services ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				<div className="services" data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="750">
					{
						content.services && content.services.map( (service, i ) => {
							return (
								<ServiceCard key={i} title={service.title} link={service.link} />
							)
						})
					}
				</div>
			</Container>
		</Section>
	)
}

Services.propTypes = {
	content: PropTypes.object,
}

export default styled(Services)`

	.services__header {
		margin-bottom: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 3rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.services {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 0.875rem;
		@media screen and (min-width: 640px) {
			column-gap: 2rem;
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 968px) {
			column-gap: 1rem;
			row-gap: 1rem;
		}
	}
	
		
	.service-card:nth-of-type(even) {
		background-color: var(--secondary-color);
	}
	
`