import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'


const Hero = ( { content, className } ) => {

	const header = () => {
		if ( content.title || content.content ) {
			return (
				<div className="hero__content">
					<Container className="max-width-xxl">
						<header className={`hero__header max-width-${content.maxWidth}`}>
							{content.title && <h1 className="title h1" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">{content.title}</h1>}
							{content.content &&
							<div className="the-content max-width-m" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="750">{ParseHTML( content.content )}</div>}
						</header>
					</Container>
				</div>
			)
		}
	}

	const image = () => {
		if ( content.image ) {
			let imageSrc
			if ( content.image.imageFile.childImageSharp !== null ) {
				imageSrc = content.image.imageFile.childImageSharp.fluid
			} else {
				imageSrc = {
					src: content.image.sourceUrl,
					sizes: content.image.sizes,
					srcSet: content.image.srcSet,
				}
			}
			return (
				<div className="hero__figure">
					<Img className="hero__image"
					     alt={content.image.altText}
					     sizes={{
						     ...content.image.sizes,
						     ...imageSrc,
						     aspectRatio: 1920 / 888,
						     objectFit: 'cover',
						     objectPosition: '50% 50%'
					     }}
					     objectFit="cover"
					     objectPosition="50% 50%"
					/>
				</div>
			)
		}
	}

	return (
		<Section className={`hero ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<div className="hero__inner">
				{image()}
				{header()}
			</div>

		</Section>
	)
}

Hero.propTypes = {
	content: PropTypes.object,
}

export default styled( Hero )`

	.title {
		margin-bottom: 1rem;
		color: var(--white);
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.the-content {
		color: var(--white);
	}
	
	.hero__inner {
	
		position: relative;
		display: flex;
		flex: auto;
		flex-direction: column;
		overflow: hidden;
	}
	
	.hero__content {
		position: absolute;
		top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
		display: flex;
		flex: auto;
		flex-direction: column;
		justify-content: center;
		z-index: 9;
		background: rgba(0,0,0, .2);
	}
	
	.hero__figure {
		flex: none;
	}
	
	.hero__image {
		> div:first-child {
			@media screen and (max-width: 767px) {
				 padding-bottom: calc((800 / 767) * 100%) !important;
			}
		}
	}
	
	.the-content {
		p {
			@media screen and (min-width: 768px) {
				font-size: 1.125rem;
			}
		}
	}
`