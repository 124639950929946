import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
/**
 * Internal components
 */
import ParseHTML from '../particles/ParseHTML'
import Button from './Button'

const Card = ( { imageObj, title, content, button, className } ) => {


	const image = () => {
		if ( imageObj ) {
			let imageSrc
			if ( imageObj.imageFile.childImageSharp !== null ) {
				imageSrc = imageObj.imageFile.childImageSharp.fluid
			} else {
				imageSrc = {
					src: imageObj.sourceUrl,
					sizes: imageObj.sizes,
					srcSet: imageObj.srcSet,
				}
			}
			return (
				<div className="card__figure">
					<Img className="card__image"
					     alt={imageObj.altText}
					     sizes={{
						     ...imageObj.sizes,
						     ...imageSrc,
						     aspectRatio: 1080 / 574,
						     objectFit: 'cover',
						     objectPosition: '50% 50%'
					     }}
					     objectFit="cover"
					     objectPosition="50% 50%"
					/>
				</div>
			)
		}
	}

	const card = () => {
		if ( title || content || button ) {
			return (
				<div className="card">
					<div className="card-inner">
						{title && <header className="card-header" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750"><h3 className="card-title h3">{title}</h3></header>}
						{content && <div className="card-content" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">{ParseHTML( content )}</div>}
						{button && <footer className="card-footer" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750"><Button title={button.title} url={button.url}
						                                                   target={button.target} light/></footer>}
					</div>
				</div>
			)
		}
	}

	return (
		<article className={`image-card ${className}`}>
			{image()}
			{card()}
		</article>
	)
}

Card.propTypes = {
	image: PropTypes.object,
	title: PropTypes.string,
	button: PropTypes.object
}

export default styled( Card )`

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		background: var(--primary-color);
		@media screen and (min-width: 1023px) {
			grid-template-columns: 2fr 1fr;
		}
		
		.card {
			padding: 2rem;
			color: var(--white);
			@media screen and (min-width: 1023px) {
				padding: 3rem 2rem 3rem 1rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
		}
		.card-header {
			margin-bottom: 1rem;
			@media screen and (min-width: 640px) {
				margin-bottom: 1.5rem;
			}
		}
		
		.card-title {
			font-size: 1.5rem;
			@media screen and (min-width: 640px) {
				font-size: 2rem;
			}
		}
		.card-footer {
			margin-top: 1rem;
			@media screen and (min-width: 640px) {
				margin-top: 1.5rem;
			}
		}	
`