import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// import Swiper core and required components
import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'


// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/effect-fade/effect-fade.scss';
/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'
import { useQueryTestimonials } from '../particles/hooks/useQueryTestimonials'

SwiperCore.use( [Pagination, Autoplay, EffectFade] )
const Testimonials = ( { content, className } ) => {

	const testimonialsData = useQueryTestimonials()

	const header = () => {
		if ( content.title || content.content ) {
			return (
				<header className="testimonials__header">
					{content.title && <h3 className="title h1">{content.title}</h3>}
					{content.content && <div className="the-content max-width-m">{ParseHTML( content.content )}</div>}
				</header>
			)
		}
	}

	const testimonials = () => {

		if ( testimonialsData.length > 0 ) {
			return (
				<div className="testimonials">
					<Container className={`max-width-${content.maxWidth}`}>
						<Swiper
							spaceBetween={0}
							slidesPerView={1}
							autoplay
							effect="fade"
							pagination={{ clickable: true }}
							onSlideChange={() => console.log( 'slide change' )}
							onSwiper={( swiper ) => console.log( swiper )}
						>
							{
								testimonialsData.map( ( testimonial ) => (
									<SwiperSlide
										key={testimonial.databaseId}>
										<div className="swiper-slide-inner">
											{ParseHTML( testimonial.content )}
											<p className="author">{testimonial.title}</p>
										</div>
									</SwiperSlide>
								) )
							}
						</Swiper>

					</Container>
				</div>
			)
		}

	}

	return (
		<Section className={`testimonials-wrapper ${className} section-space-${content.sectionSpacing}`}
		         id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
			</Container>
			{testimonials()}
		</Section>
	)
}

Testimonials.propTypes = {
	content: PropTypes.object,
}

export default styled( Testimonials )`

	.testimonials__header {
		margin-bottom: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 3rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.testimonials {
		background: rgba(127, 145, 130, .6);
		position: relative;

		p {
			color: var(--white);
			font-size: 1rem;
			line-height: 1.5;
			text-align: center;
			@media screen and (min-width: 640px) {
				font-size: 1.25rem;
			}
		}
	
		.author {
			font-family: var(--headings-font-family);
			font-size: 0.875rem;
			font-weight: var(--font-weight-bold);
			@media screen and (min-width: 640px) {
				font-size: 1rem;
			}
		}
		
		.swiper-slide {
			padding: 4rem 0;
			opacity: 0 !important;
			transition: all 300ms ease-in-out !important;
			@media screen and (min-width: 767px) {
				padding: 6rem 0;
			}
			
			&-inner {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			&-active {
				opacity: 1 !important;
			}
		}
		.swiper-pagination{
			bottom: 1rem;
		}
		.swiper-pagination-bullet{
			
			height: 10px;
			width: 10px;
			&-active {
				background: var(--tertiary-color);
			}
		}
	}

`