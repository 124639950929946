import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'
import TabButton from '../atoms/TabButton'
import TabPanel from '../atoms/TabPanel'

const Tabs = ( { content, className } ) => {

	//const startTab = ( location.state && location.state.tab ) ? location.state.tab : 0
	const [tabIndex, setTabIndex] = useState( 0 )


	const header = () => {
		if ( content.title || content.content ) {
			return (
				<header className="tabs__header">
					{content.title && <h3 className="title h1">{content.title}</h3>}
					{content.content && <div className="the-content max-width-m">{ParseHTML( content.content )}</div>}
				</header>
			)
		}
	}

	const tabs = () => {
		if ( content.tabs ) {
			return (
				<div className="tabs">
					<header className={`tabs-header`} role="tablist">
						{
							content.tabs.map( ( tab, i ) => (
								<TabButton title={tab.title} key={i.toString()} slug={i} onClick={() => setTabIndex( i )}
								           selected={i === tabIndex}/>
							) )
						}
					</header>
					{
						content.tabs.map( ( tab, i ) => (
							<TabPanel key={i} slug={i.toString()} hidden={i !== tabIndex}>
								{ParseHTML( tab.content)}
							</TabPanel>
						) )
					}
				</div>
			)
		}
	}

	return (
		<Section className={`tabs ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				{tabs()
				}
			</Container>
		</Section>
	)
}

Tabs.propTypes = {
	content: PropTypes.object,
}

export default styled( Tabs )`
	
	.tabs__header {
		margin-bottom: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 3rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.tabs {
		
	}
	
	.tabs-header {
	    display: flex;
		align-items: flex-end;
		@media screen and (max-width: 641px) {
			white-space: nowrap;
            overflow: auto;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
		}

	}
	
	.tab-button {
	  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
	}
	
`