import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */

const TabButton = ( { className, title, slug, onClick, selected = false, index = 0 } ) => {
	if ( selected ) {
		index = -1
	}
	return (
		<button role="tab"
		        className={`tab-button ${className}`}
		        aria-selected={selected} aria-controls={`panel-${slug}`}
		        id={slug} tabIndex={index} data-name={slug} onClick={onClick}>
			{title}
		</button>
	)
}

TabButton.propTypes = {
	title: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	index: PropTypes.number
}

export default styled( TabButton )`
	border: 1px solid var(--primary-color);;
	outline: none;
	padding: 1rem;
	background: var(--white);
	color: var(--primary-color);
	font-family: var(--headings-font-family);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1;
	text-align: center;
	transition: all .3s ease-in-out;
	cursor: pointer;
	@media screen and (min-width: 640px) {
		padding: 1.5rem 1rem;
		font-size: 1.125rem; 
	}
	@media screen and (min-width: 769px) {
	font-size: 1.25rem;
	}
	&[aria-selected="true"] {
		background: var(--primary-color);
		color: var(--white);
	}
`
