import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'

const ImagesContent = ( { content, className } ) => {

	const header = () => {
		console.log(content.content )
		if ( content.title || content.content ) {
			return (
				<header className="images__header">
					{content.title && <h3 className="title h1">{content.title}</h3>}
					{content.content && <div className="the-content max-width-m">{ParseHTML( content.content )}</div>}
				</header>
			)
		}
	}

	return (
		<Section className={`images ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				<div className={`images ${content.images.length > 1 ? 'multiple' : 'single' }`}  data-sal="fade" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
					{
						content.images && content.images.map( ( image, i ) => {
							let imageSrc
							if ( image.image.imageFile.childImageSharp !== null ) {
								imageSrc = image.image.imageFile.childImageSharp.fluid
							} else {
								imageSrc = {
									src: image.image.sourceUrl,
									sizes: image.image.sizes,
									srcSet: image.image.srcSet,
								}
							}
							return (
								<div key={i} className="hero__figure">
									<Img className="hero__image"
									     alt={image.image.altText}
									     sizes={{
										     ...image.image.sizes,
										     ...imageSrc,
										     aspectRatio: 944 / 520,
										     objectFit: 'cover',
										     objectPosition: '50% 50%'
									     }}
									     objectFit="cover"
									     objectPosition="50% 50%"
									/>
								</div>
							)
						} )
					}
				</div>
			</Container>
		</Section>
	)
}

ImagesContent.propTypes = {
	content: PropTypes.object,
}

export default styled( ImagesContent )`

	.images__header {
		margin-bottom: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 3rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.images {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 0.875rem;
		@media screen and (min-width: 640px) {
			column-gap: 2rem;
		}	
		&.single {
			grid-template-columns: 1fr;
		}
		@media screen and (max-width: 321px) {
			grid-template-columns: 1fr;
		}
	}
`