import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */

import PageLink from './PageLink'

const ServiceCard = ( { title, link, className } ) => {


	const header = () => {
		if ( title ) {
			return (
				<header data-sal-delay="400" data-sal-easing="ease" data-sal-duration="750">
					<h2 className="h2 card-title">{title}</h2>
				</header>
			)
		}
	}
	const footer = () => {
		if ( link ) {
			return (
				<footer className="card-footer" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="750">
					<p className="p link">
						<PageLink url={link.url} title={link.title} />
					</p>
				</footer>
			)
		}
	}

	return (
		<article className={`service-card ${className}`}  data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
			{header()}
			{footer()}
		</article>
	)
}

ServiceCard.propTypes = {
	title: PropTypes.string,
	link: PropTypes.object
}

export default styled( ServiceCard )`
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	
	background: var(--primary-color);
	
	@media screen and (min-width: 967px) {
		padding: 2rem 1.5rem;
	}
	
	.card-title {
		margin: 0;
		color: var(--white);
		font-size: 1.5rem;
		font-weight: var(--font-weight-regular);
		@media screen and (min-width: 967px) {
			font-size: 2rem; 
		}
	}
	
	.link {
		margin: 0;
		color: var(--white);
		font-size: 0.875rem; 
		text-transform: uppercase;
	}
	
	.card-footer {
		margin-top: 1rem;
	}

`