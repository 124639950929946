import React, {useEffect} from 'react'

/**
 * Internal components
 */
import Layout from '../particles/Layout'
import PageHead from '../molecules/PageHead'
import FullwidthCTA from '../molecules/FullwidthCTA'
import FullwidthContent from '../molecules/FullwidthContent'
import Hero from '../molecules/Hero'
import ImagesContent from '../molecules/Images'
import ImageContent from '../molecules/ImageContent'
import ImageCard from '../molecules/ImageCard'
import Services from '../molecules/Services'
import Statistics from '../molecules/Statistics'
import Tabs from '../molecules/Tabs'
import Testimonials from '../molecules/Testimonials'
import Contact from '../molecules/Contact'


const PageTemplate = ( props ) => {
	const { layouts, postType } = props.pageContext

	const flexiContent = ( content, key ) => {
		switch ( content.fieldGroupName ) {
			case `${postType}_Flexicontent_FlexiContent_Contact` :
				return <Contact content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_FullwidthContent` :
				return <FullwidthContent content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_FullwidthCta` :
				return <FullwidthCTA content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_Hero` :
				return <Hero content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_ImageCard` :
				return <ImageCard content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_ImageContent` :
				return <ImageContent content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_Images` :
				return <ImagesContent content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_Services` :
				return <Services content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_PageHead` :
				return <PageHead content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_Statistics` :
				return <Statistics content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_Tabs` :
				return <Tabs content={content} key={key}/>
			case `${postType}_Flexicontent_FlexiContent_TestimonialCarousel` :
				return <Testimonials content={content} key={key}/>
			default:
				return null
		}
	}

	useEffect(() => {
		window.scrollTo(0,0)
	}, [props.pageContext])

	const layout = () => {
		if ( layouts ) {
			return layouts.map( ( layout, index ) => {
				return flexiContent( layout, index )
			} )
		} else {
			return ''
		}
	}

	return (
		<Layout context={props.pageContext}>
			{layout()}
		</Layout>
	)
}

export default PageTemplate