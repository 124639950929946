import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'
import Button from '../atoms/Button'

const FullwidthCTA = ( { content, className } ) => {

	const header = () => {
		if ( content.title ){
			return  (
				<header data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
					<h3 className="title h1">{content.title}</h3>
				</header>
			)
		}
	}

	const body = () => {
		if ( content.content ) {
			return  (
				<div className="the-content" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="750">
					{ParseHTML(content.content)}
				</div>
			)
		}
	}

	const footer = () => {
		if ( content.button ) {
			return (
				<footer data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="750">
					<Button title={content.button.title} url={content.button.url}/>
				</footer>
			)
		}
	}

	return (
		<Section className={`fullwidth-cta ${className} background-${content.background} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				{body()}
				{footer()}
			</Container>
		</Section>
	)
}

FullwidthCTA.propTypes = {
	content: PropTypes.object,
}

export default styled(FullwidthCTA)`
	padding: 4rem 0;

	@media screen and (min-width: 640px) {
		padding: 9rem 0;
	}
	
	.title {
		font-size: 2rem; 
		font-weight: var(--font-weight-regular);
		text-align: center;
		@media screen and (min-width: 768px) {
			font-size: 3rem; 
		}
	}
	header {
		+ .the-content,
		+ footer {
			margin-bottom: 1rem; 
			@media screen and (min-width: 640px) {
				margin-bottom: 1.5rem; 
			}
		}
	}
	.the-content{
		+ footer {
			margin-bottom: 1rem; 
			@media screen and (min-width: 640px) {
				margin-bottom: 1.5rem; 
			}
		}
	}
	
	footer {
		text-align: center;
	}

`