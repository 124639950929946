import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */
import Section from './../organisms/Section'
import Container from '../organisms/Container'
import ParseHTML from '../particles/ParseHTML'
import { useSiteOptionsData } from '../particles/hooks/useSiteOptionsData'
import { useSiteSocialData } from '../particles/hooks/useSiteSocialData'
import SocialWidget from './SocialWidget'
import FooterWidget from './FooterWidget'
import ContactForm from '../atoms/ContactForm'

const Contact = ( { content, className } ) => {
	const optionsData = useSiteOptionsData()
	const socialMedia = useSiteSocialData()

	const email = optionsData[ 0 ][ 'options' ][ 'email' ]
	const phone = optionsData[ 0 ][ 'options' ][ 'phone' ]

	const columnLeft = () => {
		if ( content.title || content.content ) {
			return (
				<div className={`column-left`}>
					{content.title && <h1 className="title h1">{content.title}</h1>}
					{content.content && <div className="the-content">{ParseHTML( content.content )}</div>}
					<ContactForm endpoint="https://l00ufargnb.execute-api.ap-southeast-2.amazonaws.com/default/ybds_email"/>
				</div>
			)
		}
	}
	const social = ( ) => {
		if ( socialMedia.facebook || socialMedia.instagram ) {
			return (
				<SocialWidget title="Follow us" facebook={socialMedia.facebook} instagram={socialMedia.instagram} />
			)
		}
	}

	const columnRight = () => {
		return (
			<div className={`column-right`}>
				<h3 className="title h3">Contact Details</h3>
				{
					phone && <FooterWidget title="Phone" text="+614 0241 8273" />
				}
				{
					email && <FooterWidget title="Enquires" url={`mailto:${email}`} text={email} />
				}
				{social()}

			</div>
		)

	}
	return (
		<Section className={`contact ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				<div className="contact-wrapper">
					{columnLeft()}
					{columnRight()}
				</div>

			</Container>
		</Section>
	)
}

Contact.propTypes = {
	content: PropTypes.object,
}

export default styled( Contact )`

	h1.title {
		margin-bottom: 1rem; 
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 1.25rem; 
			font-size: 3rem; 
		}
	}
	h3 {
		margin-bottom: 1rem; 
		font-size: 1.25rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 1.25rem; 
			font-size: 1.5rem; 
		}
	}
	
	.footer-widget {
		margin-bottom: 1rem;
		@media screen and (min-width: 640px) {
			margin-bottom: 1.25rem; 
		} 
	}
	
	.contact-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 2rem;
		row-gap: 2rem;
		@media screen and (min-width: 767px)  {
			grid-template-columns: 3fr 2fr;
			column-gap: 4rem;
		}
	}
`