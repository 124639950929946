import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'
import StatisticCard from '../atoms/StatisticCard'

const Statistics = ( { content, className } ) => {

	const header = () => {
		if ( content.title || content.content ) {
			return (
				<header className="statistics__header" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
					{content.title && <h3 className="title h1">{content.title}</h3>}
					{content.content && <div className="the-content max-width-m">{ParseHTML( content.content )}</div>}
				</header>
			)
		}
	}


	return (
		<Section className={`statistics ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				<div className="statistics" data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="750">
					{
						content.statistics && content.statistics.map( ( statistic, i ) => {
							return (
								<StatisticCard key={i} title={statistic.title} description={statistic.content}/>
							)
						} )
					}
				</div>
				{ content.footnote && <div className="footnote max-width-m">{ParseHTML(content.footnote)}</div>}
			</Container>
		</Section>
	)
}

Statistics.propTypes = {
	content: PropTypes.object,
}

export default styled( Statistics )`

	.statistics__header {
		margin-bottom: 2rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 3rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
	.statistics {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 0.875rem;
		
		@media screen and (min-width: 640px) and (max-width: 1279px) {
			column-gap: 2rem;
			row-gap: 2rem;
			grid-template-columns: 1fr 1fr;
		}	
		@media screen and (max-width: 968px) {
			column-gap: 1rem;
			row-gap: 1rem;
		}
		@media screen and (min-width: 1280px) {
			column-gap: 2rem;
			row-gap: 2rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	
	.statistic-card:nth-of-type(even) {
		background-color: var(--secondary-color);
	}
	
	.footnote {
		margin-top: 1rem;
		@media screen and (min-width: 640px) {
			margin-top: 2rem;
		}
		p {
			font-size: 0.75rem;
		}
	
	}
`